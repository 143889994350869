import React from "react"
import { graphql } from "gatsby"
import PageTitle from "components/pageTitle"
import Layout from "components/layout"
import Seo from "components/seo"
import ReactHtmlParser from "html-react-parser"

const PrivacyPolicyPage = ({ data }) => {
  return (
    <Layout>
      <Seo title="Privacy Policy" />
      <div className="privacy-policy-page">
        <div className="contents">
          <PageTitle title="Privacy Policy" subTitle="プライバシーポリシー" />
          {ReactHtmlParser(
            data.strapiPrivacyPolicyPrivacyPolicyTextnode?.privacy_policy ?? ""
          )}
          <PageTitle
            title="Handling Of Personal Information"
            subTitle="個人情報の取扱いについて"
          />
          {ReactHtmlParser(
            data.strapiPersonalInformationPersonalInformationTextnode
              ?.personal_information ?? ""
          )}
        </div>
      </div>
    </Layout>
  )
}

export const Head = () => (
  <Seo
    title="Privacy Policy"
    description="株式会社OKULABは、当社の提供するサービス及び事業で取得した個人情報の保護について、社会的責任を十分に認識して、本人の権利利益を保護し、個人情報に関する法規制等を遵守いたします。"
  />
)
export default PrivacyPolicyPage

export const query = graphql`
  query {
    strapiPrivacyPolicyPrivacyPolicyTextnode {
      privacy_policy
    }
    strapiPersonalInformationPersonalInformationTextnode {
      personal_information
    }
  }
`
